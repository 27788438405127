import {CheckoutModel} from '../domain/models/checkout/Checkout.model';
import {CheckoutErrorModel} from '../domain/models/checkout/CheckoutError.model';
import {CheckoutSettingsModel} from '../domain/models/checkout/checkoutSettings/CheckoutSettings.model';
import {PolicyButtonLocation, PolicyType} from '../common/components/PolicyButtonWithDialog/PolicyButtonWithDialog';
import {ITrackEventName, ITrackEventParams} from '@wix/native-components-infra';
import {SlotId} from '../domain/utils/slotId';
import {PaymentsWidgetConfiguration} from '@wix/cashier-payments-widget/dist/src/types/PaymentsWidgetConfiguration';
import {PaymentsWidgetProps} from '@wix/cashier-payments-widget/dist/src/types/PaymentsWidgetProps';
import {MemberAddressesInfoModel} from '../domain/models/checkout/MemberAddressesInfo.model';
import {PaymentMethod} from '@wix/cashier-payments-widget';
import {CashierMandatoryFieldsOverrides, PaymentError} from './payment.types';
import {
  AddressWithContactFragment,
  AddressWithContactNoGeoFragment,
  ApiAddressFragment,
  ApiAddressNoGeoFragment,
  CheckoutFragment,
  FullAddressContactDetailsFragment,
  CheckoutOldFragment,
} from '../gql/graphql';
import {AddressWithContactModel} from '../domain/models/checkout/AddressWithContact.model';
import {BiAddressActionOrigin, BiMobilePosition} from '../domain/utils/bi.util';
import {ViolationModel} from '../domain/models/checkout/Violation.model';
import {confirmPayment} from '@wix/ecom-platform-express-checkout-buttons';
import {DataExtendedFieldView} from '@wix/wixstores-client-storefront-sdk';
import {
  checkoutOpenCloseYourOrderSummaryInMobileParams,
  checkoutShowHideItemsSectionInMobileViewParams,
} from '@wix/bi-logger-ec-site/v2/types';
import {FormOverride} from '@wix/form-viewer/dist/types/services/form-overrides';
import {FormControllerAPI} from '@wix/form-viewer';
import {CheckoutContentModel} from '../domain/models/checkout/CheckoutContent.model';
import {SuggestedFix} from '../domain/models/checkout/ViolationLineItemTargetModel.model';

export declare type OnlyFunctionProps<O> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof O]: O[K] extends Function ? K : never;
}[keyof O];

export type CheckoutControllerProps = {
  isOneColumnView?: boolean;
  isLoading: boolean;
  failedToFetch?: boolean;
  checkoutStore: CheckoutStoreProps;
  deliveryMethodStore: DeliveryMethodStoreProps;
  checkoutSettingsStore: CheckoutSettingsStoreProps;
  navigationStore: NavigationStoreProps;
  slotsStore: SlotStoreProps;
  checkboxesStore: CheckboxesStoreProps;
  stepsManagerStore: StepsManagerStoreProps;
  paymentStore: PaymentStoreProps;
  formsStore: FormsStoreProps;
  memberStore: MemberStoreProps;
};

export interface CheckoutStoreProps {
  checkout: CheckoutModel;
  extendedFieldsView: DataExtendedFieldView[];
  placeOrderError?: CheckoutErrorModel;
  setPlaceOrderPaymentError: (paymentError: PaymentError, errorCode: string) => void;
  applyCouponError?: CheckoutErrorModel;
  applyGiftCardError?: CheckoutErrorModel;
  updateCheckoutError?: CheckoutErrorModel;
  hasPartialOutOfStockError?: boolean;
  onInvalidDetailsFormSubmit: () => void;
  isPlaceOrderButtonDisabled: boolean;
  shouldRequireZipCode: boolean;
  shouldShowSubdivisionSelector: boolean;
  isFastFlow: boolean;
  isPreselectedFlow: boolean;
  isPickupFlow: boolean;
  isShippingFlow: boolean;
  applyCoupon: (couponCode: string, mobilePosition?: string) => Promise<void>;
  removeCoupon: (mobilePosition?: string) => Promise<void>;
  applyGiftCard: (giftCardCode: string, mobilePosition?: string) => Promise<void>;
  removeGiftCard: (mobilePosition?: string) => Promise<void>;
  setFastFlowFormFields: (
    extendedFieldsValue: any,
    customFieldValue?: string,
    shouldSubscribe?: boolean
  ) => Promise<void>;
  removeLineItem: (lineItemId: string) => Promise<void>;
  clickPlaceOrderButton: (
    shouldSubscribe: boolean,
    detailsId?: string
  ) => Promise<
    | {
        orderId?: string | null;
        subscriptionId?: string;
        paymentResponseToken?: string | null;
        paymentError?: PaymentError;
      }
    | undefined
  >;
  setPaymentAndBillingDetailsV2: ({
    contactDetails,
    address,
    addressesServiceId,
    setBillingSameAsShipping,
    activePaymentId,
  }: {
    contactDetails?: FullAddressContactDetailsFragment;
    address?: ApiAddressFragment;
    addressesServiceId?: string;
    setBillingSameAsShipping: boolean;
    activePaymentId?: string;
  }) => Promise<boolean>;
  setBillingDetails: ({
    contactDetails,
    address,
    addressesServiceId,
    setAsDefault,
  }: {
    contactDetails?: FullAddressContactDetailsFragment;
    address?: ApiAddressFragment;
    addressesServiceId?: string;
    setAsDefault?: boolean;
  }) => Promise<void>;
  submitCustomerDetails: ({
    contactDetails,
    email,
    customFieldValue,
    extendedFieldsValue,
    shippingAddress,
    addressesServiceId,
    setAsDefault,
    shouldSubscribe,
  }: {
    contactDetails?: FullAddressContactDetailsFragment;
    email?: string;
    customFieldValue?: string;
    shippingAddress?: ApiAddressFragment;
    addressesServiceId?: string;
    extendedFieldsValue?: any;
    setAsDefault?: boolean;
    shouldSubscribe?: boolean;
  }) => Promise<void>;
  onDeliveryMethodContinue: () => void;
  onHideBillingDetails: () => void;
  setZipCode: (zipCodeValue: string) => Promise<void>;
  onAddCouponSectionOpen: (mobilePosition?: string) => void;
  onAddGiftCardSectionOpen: (mobilePosition?: string) => void;
  onErrorDialogClosed: () => void;
  onErrorDialogOpened: () => void;
  onPolicyClicked: (linkLocation: PolicyButtonLocation, policyType: PolicyType) => void;
  onMobileFoldableSummaryToggle: (
    mobilePosition: BiMobilePosition,
    partialParams:
      | Partial<checkoutOpenCloseYourOrderSummaryInMobileParams>
      | Partial<checkoutShowHideItemsSectionInMobileViewParams>
  ) => void;
  setSubdivision: (subdivisionValue: string) => Promise<void>;
  setIsSubscriptionCheckboxCheckedByDefault: (isSubscriptionCheckboxCheckedByDefault: boolean) => void;
  shouldShowGiftCardSection: boolean;
  shouldShowCouponSection: boolean;
  onLogoutDialogToggled: (isOpen: boolean) => void;
  shouldShowViolations: boolean;
  mainViolationsToDisplay: ViolationModel[];
  defaultViolationsToDisplay: ViolationModel[];
  deliveryViolationsToDisplay: ViolationModel[];
  shouldShowAddShippingAddressSection: boolean;
  isPickupButShippingFlow: boolean;
  AddShippingAddressToPreselectedFlow: () => void;
  isCouponLoading: boolean;
  isGiftCardLoading: boolean;
  fixCustomPolicyLongText: boolean;
  checkoutPageFinishLoading: () => void;
  reportViolationRemoveButtonClick: ({
    message,
    lineItemId,
    suggestedFix,
  }: {
    message?: string;
    lineItemId?: string;
    suggestedFix?: SuggestedFix;
  }) => void;
}

export interface PaymentStoreProps {
  cashierWidgetProps: PaymentsWidgetProps;
  cashierConfiguration: PaymentsWidgetConfiguration;
  cashierMandatoryFields: CashierMandatoryFieldsOverrides;
  confirmPayment: (arg: {chargeResponseToken: string; newApi: boolean}) => ReturnType<typeof confirmPayment>;
  setCashierMandatoryFields: (cashierMandatoryFields: CashierMandatoryFieldsOverrides) => void;
  shouldRequirePayment: boolean;
  expressPaymentMethodsAmount: number;
}

export interface FormsStoreProps {
  areFormsLoaded: boolean;
  doesFormHaveSubscription: boolean;
  isSubscriptionCheckboxCheckedByDefault: boolean | null;
  validateBillingAddress: (addressWithContact: AddressWithContactModel) => Promise<boolean>;
  validateShippingAddress: (addressWithContact: AddressWithContactModel) => Promise<boolean>;
  validateShippingAddressForBilling: (addressWithContact: AddressWithContactModel) => Promise<boolean>;
  shippingDetailsFieldsProps: FieldPropertiesByTarget | null;
  dataExtendedFieldsTargets: string[] | null;
  shouldSubscribe: boolean | null;
}

export interface MemberAddressActionBiParams {
  checkout: CheckoutModel;
  origin: BiAddressActionOrigin;
  stage: string;
  addressServiceId?: string;
}
export interface MemberStoreProps {
  isMember: boolean;
  addressesInfo: MemberAddressesInfoModel;
  defaultAddressId?: string;
  isAddressesAppInstalled: boolean;
  isMemberAreaAppInstalled: boolean;
  currentUserEmail: string;
  reportEditAddressClicked: (params: MemberAddressActionBiParams) => void;
  reportAddAddressClicked: (params: MemberAddressActionBiParams) => void;
}

export interface CheckboxesStoreProps {
  isPolicyCheckboxChecked: boolean;
  isPaymentPolicyCheckboxChecked?: boolean;
  toggleIsPolicyCheckboxChecked: (checked: boolean) => void;
  toggleIsPaymentPolicyCheckboxChecked: (checked: boolean) => void;
  isDigitalPolicyCheckboxChecked: boolean;
  toggleIsDigitalPolicyCheckboxChecked: (checked: boolean) => void;
  isSubscriptionCheckboxChecked: boolean;
  toggleIsSubscriptionCheckboxChecked: (checked: boolean) => void;
  areCheckoutCheckboxesValid: boolean;
  wasFormSubmitted: boolean;
  setWasFormSubmitted: (wasSubmitted: boolean) => void;
  shouldShowCheckboxesSection: boolean;
}

export interface StepsManagerStoreProps {
  updateStepOnStage: (stepIndex: number, stepId: StepId) => void;
  openStep: (stepIndex: number) => void;
  sendEditStepClickedBIEvent: (stepId: StepId, previousStepName?: StepId) => void;
  shouldDisplayExpressCheckout: boolean;
  activeStep: ActiveStep;
  stepsList: CheckoutStep[];
  hasMovedToNextStep: boolean;
}

export interface DeliveryMethodStoreProps {
  canShipToDestination: boolean;
  setShippingOption: (shippingOptionId: string) => Promise<void>;
  hasShippingOptions: boolean;
  isUpdatingShippingOption: boolean;
  beforePlaceOrderClicked: () => void;
  locale: string;
}

export interface NavigationStoreProps {
  clickOnBackToSite: () => void;
  clickOnContinueShopping: () => void;
  clickOnReturnToCart: () => Promise<void>;
  clickOnEditCart: (mobilePosition?: string) => void;
  navigateToThankYouPage: (params: navigateToThankYouPageParams) => Promise<void> | void;
  continueShoppingUrl: string;
  editCartUrl: string;
  successUrl?: string;
  isDonationsTheme?: boolean;
  trackEvent: (eventName: ITrackEventName, eventProps: ITrackEventParams) => void;
  onLogin: () => Promise<void>;
  onLogout: () => Promise<void>;
  isSSR: boolean;
  isContinueShoppingEnabled: boolean;
}

export interface CheckoutSettingsStoreProps {
  checkoutSettings: CheckoutSettingsModel;
  showCouponSP: boolean;
  shouldDisplayCheckoutPolicies: boolean;
  doesFastFlowFormHaveFieldsToDisplay: boolean;
  showExpressCheckoutSP: boolean;
  checkoutContent: CheckoutContentModel;
}

export interface SlotStoreProps {
  setSlotsParams: (slotId: SlotId) => void;
  setStepId: (slotId: SlotId, stepId: StepId | null) => void;
  setPaymentId: (slotId: SlotId, paymentId?: PaymentMethod) => void;
}

export type PlaceOrderUrlParams = {
  errorUrl?: string;
  cancelUrl?: string;
  successUrl?: string;
  pendingUrl?: string;
};

export type navigateToThankYouPageParams = {
  orderId?: string;
  isSubscription: boolean;
};

export type navigateToSuccessPageParams = {
  orderId?: string;
  objectType?: string;
};

export enum FullNameTarget {
  first_name = 'first_name',
  last_name = 'last_name',
}

export enum AddressTarget {
  country = 'country',
  address_line = 'address_line',
  address_line_2 = 'address_line_2',
  street_name = 'street_name',
  street_number = 'street_number',
  postal_code = 'postal_code',
  subdivision = 'subdivision',
  city = 'city',
}

export enum FormHeaderId {
  customer_details_header = '9decce65-bf68-4a14-e170-8242eb363d2b',
  shipping_details_header = '07760e04-b85a-47a7-91e0-ad94fd6c0dd6',
}

export enum FormFieldTarget {
  email = 'email',
  phone = 'phone',
  full_name = 'full_name',
  company_name = 'company_name',
  address = 'address',
  additional_info_1 = 'additional_info_1',
  extendedField = 'user_custom_field',
  vat_id = 'vat_id',
  subscribe = 'subscribe',
}

export type FormOverrides = CustomerDetailsFormOverrides | BillingFormOverrides;

export interface CustomerDetailsFormValues {
  [FormFieldTarget.email]?: string;
  [FormFieldTarget.full_name]?: {
    [FullNameTarget.first_name]?: string;
    [FullNameTarget.last_name]?: string;
  };
  [FormFieldTarget.company_name]?: string;
  [FormFieldTarget.phone]?: string;
  [FormFieldTarget.additional_info_1]?: string;
  [FormFieldTarget.address]?: {
    [AddressTarget.country]?: string;
    [AddressTarget.city]?: string;
    [AddressTarget.subdivision]?: string;
    [AddressTarget.postal_code]?: string;
    [AddressTarget.address_line]?: string;
    [AddressTarget.street_name]?: string;
    [AddressTarget.street_number]?: string;
    [AddressTarget.address_line_2]?: string;
  };
}

export type FullNameOverrides = {
  [key in FullNameTarget]?: FormOverride;
};

export type AddressOverrides = {
  [key in AddressTarget]?: FormOverride;
};

export interface BillingFormOverrides {
  [FormFieldTarget.full_name]?: FullNameOverrides;
  [FormFieldTarget.phone]?: FormOverride;
  [FormFieldTarget.company_name]?: FormOverride;
  [FormFieldTarget.address]?: AddressOverrides;
  [FormFieldTarget.additional_info_1]?: FormOverride;
  [FormFieldTarget.vat_id]?: FormOverride;
}

export interface CustomerDetailsFormOverrides {
  [FormFieldTarget.email]?: FormOverride;
  [FormFieldTarget.full_name]?: FullNameOverrides;
  [FormFieldTarget.phone]?: FormOverride;
  [FormFieldTarget.company_name]?: FormOverride;
  [FormFieldTarget.address]?: AddressOverrides;
  [FormFieldTarget.additional_info_1]?: FormOverride;
  [FormFieldTarget.subscribe]?: FormOverride;
  [FormHeaderId.customer_details_header]?: FormOverride;
  [FormHeaderId.shipping_details_header]?: FormOverride;
}

export enum CustomerDetailsFormSection {
  email = 'email',
  subscription = 'subscription',
  contact = 'contact',
  address = 'address',
  additionalInfo = 'additionalInfo',
  extendedFields = 'extendedFields',
}

export type FieldPropertiesByTarget = NonNullable<ReturnType<FormControllerAPI['getFieldPropertiesByTarget']>>;

export enum StepState {
  EMPTY = 'empty',
  OPEN = 'open',
  COLLAPSED = 'collapsed',
}

export type CheckoutStep = {
  id: StepId;
  state: StepState;
};

export type StepImplementationProps = {
  index?: number;
};

export enum StepId {
  customerDetails = 'contact-details',
  deliveryMethod = 'delivery-method',
  payment = 'payment-and-billing',
  paymentAndPlaceOrder = 'payment-and-place-order',
}

export type ActiveStep = {
  stepId: StepId;
  stepIndex: number;
};

export enum ViolationsAndPlaceOrderButtonLocation {
  summary = 'summary',
  paymentAndPlaceOrderStep = 'paymentAndPlaceOrderStep',
  fastFlow = 'fastFlow',
}

export enum SummaryLocation {
  checkoutApp = 'checkoutApp',
  paymentAndPlaceOrderStep = 'paymentAndPlaceOrderStep',
}

export type Checkout = CheckoutOldFragment | CheckoutFragment;
export type AddressWithContact = AddressWithContactFragment | AddressWithContactNoGeoFragment;
export type ApiAddress = ApiAddressFragment | ApiAddressNoGeoFragment;
